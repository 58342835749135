import { Api } from 'lib/api'
import { IStatus } from 'lib/types/response'

import { IAgency } from '../types/IAgency'

import { fromDataToPayload } from './adapter'

export const updateAgency = (data: IAgency) => {
  return Api
    .post<IStatus>('partner/agency/save', fromDataToPayload(data))
    .then(res => res)
}
