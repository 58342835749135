export default [
  {
    path: '/balance',
    name: 'balance',
    component: () => import('Partner/pages/PartnerBalance'),
    meta: { title: 'Partner Balance - Uplify' },
  },
  {
    path: '/creators',
    name: 'creators',
    component: () => import('Partner/pages/PartnerCreators'),
    meta: { title: 'Partner creators - Uplify' },
  },
  {
    path: '/partner/profile',
    name: 'profile',
    component: () => import('Partner/pages/PartnerProfile'),
    meta: { title: 'Partner Profile - Uplify' },
  },

  // {
  //   path: '/campaigns',
  //   name: 'campaigns',
  //   component: () => import('Partner/pages/PartnerCampaigns'),
  //   meta: { title: 'Partner Campaigns - Uplify' },
  // },
  // {
  //   path: '/campaigns/awareness',
  //   name: 'awareness',
  //   component: () => import('Partner/pages/PartnerCampaigns'),
  //   meta: { title: 'Partner Campaigns - Uplify' },
  // },
  {
    path: '/campaigns',
    name: 'campaigns',
    redirect: { name: 'brand-awareness-campaigns' },
  },
  {
    path: '/sponsorship',
    name: 'brand-awareness',
    redirect: { name: 'brand-awareness-campaigns' },
  },
  {
    path: '/sponsorship/campaigns',
    name: 'brand-awareness-campaigns',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/sponsorship/groups',
    name: 'brand-awareness-groups',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/sponsorship/creatives',
    name: 'brand-awareness-creatives',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/interactive',
    name: 'performance',
    redirect: { name: 'performance-campaigns' },
  },
  {
    path: '/interactive/campaigns',
    name: 'performance-campaigns',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/interactive/groups',
    name: 'performance-groups',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/preroll',
    name: 'preroll',
    redirect: { name: 'preroll-campaigns' },
  },
  {
    path: '/preroll/campaigns',
    name: 'preroll-campaigns',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/preroll/groups',
    name: 'preroll-groups',
    component: () => import('Partner/pages/PartnerCampaigns'),
    meta: { locale: 'campaigns', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/campaigns/create',
    name: 'create-campaign',
    component: () => import('Partner/pages/PartnerCampaign'),
    meta: { locale: 'campaign', title: 'Create Campaign - Uplify' },
  },
  {
    path: '/campaign/:campaignSlug',
    name: 'edit-campaign',
    component: () => import('Partner/pages/PartnerCampaign'),
    meta: { locale: 'campaign', title: 'Edit Campaign - Uplify' },
  },
  {
    path: '/campaign/:campaignSlug/analytics',
    name: 'analytics',
    component: () => import('Partner/pages/PartnerAnalytics'),
    meta: { title: 'Analytics - Uplify' },
  },
  {
    path: '/campaign/:campaignSlug/group/create',
    name: 'create-group',
    component: () => import('Partner/pages/PartnerGroup'),
    meta: { locale: 'group', title: 'Create Group - Uplify' },
  },
  {
    path: '/campaign/:campaignSlug/group/:groupSlug',
    name: 'edit-group',
    component: () => import('Partner/pages/PartnerGroup'),
    meta: { locale: 'group', title: 'Edit Group - Uplify' },
  },
  {
    path: '/campaign/:campaignSlug/group/:groupSlug/creative/create',
    name: 'create-creative',
    component: () => import('Partner/pages/PartnerCreative'),
    meta: {
      locale: 'creative',
      title: 'Create Creative - Uplify',
    },
  },
  {
    path: '/campaign/:campaignSlug/group/:groupSlug/creative/:creativeSlug',
    name: 'edit-creative',
    component: () => import('Partner/pages/PartnerCreative'),
    meta: { locale: 'creative', title: 'Edit Creative - Uplify' },
  },
  {
    path: '/advertisers/',
    name: 'advertisers',
    component: () => import('Partner/pages/PartnerAdvertisers'),
    meta: { locale: 'advertisers', title: 'Partner Campaigns - Uplify' },
  },
  {
    path: '/debug/:apiVersion?/:slug?',
    name: 'debug',
    component: () => import('views/WidgetOld/Debug'),
    meta: { title: 'Debug - Uplify' },
  },
  {
    path: '/',
    name: 'dashboard',
    redirect: { name: 'campaigns' },
  },
  {
    path: '/auth/*',
    redirect: { name: 'dashboard' },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
    meta: { title: '404 - Uplify' },
  },
]
