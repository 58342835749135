import {
  IReferralCreators,
  IReferralHistory,
} from 'lib/types/partner/creators/referral'
import {
  IReferralCreatorsResponse,
  IReferralHistoryResponse,
} from 'lib/types/payloads/partner/creators/referral'

export const fromResponseToDataReferralHistoryAdapter = (response: IReferralHistoryResponse): IReferralHistory => ({
  amount: response.amount,
  total: response.total,
  list: response.data,
  perPage: response.per_page,
})

export const fromResponseToDataReferralCreatorsAdapter = (response: IReferralCreatorsResponse): IReferralCreators => ({
  amount: response.amount,
  list: response.data,
})
