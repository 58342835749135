import {
  fromResponseToDataReferralCreatorsAdapter,
  fromResponseToDataReferralHistoryAdapter,
} from 'lib/adapters/partner/creators/referral'
import { Api } from 'lib/api'
import { IdType } from 'lib/types/base-types'
import { IReferral, IReferralHistoryDetailItem } from 'lib/types/partner/creators/referral'
import {
  IReferralCreatorsResponse,
  IReferralHistoryResponse,
} from 'lib/types/payloads/partner/creators/referral'
import { IResponse, IResponseData, IStatus } from 'lib/types/response'

export const getReferral = () => {
  return Api
    .get<IResponseData<IReferral>>('partner/referral')
    .then(res => res)
}

export const getReferralHistory = (page: number) => {
  return Api
    .get<IResponse<IReferralHistoryResponse>>('partner/referral/history', { page })
    .then(res => fromResponseToDataReferralHistoryAdapter(res))
}

export const getReferralHistoryDetail = (date: string) => {
  return Api
    .get<IResponseData<IReferralHistoryDetailItem[]>>('partner/referral/history/detail', { date })
    .then(res => res)
}

export const getReferralCreators = () => {
  return Api
    .get<IResponse<IReferralCreatorsResponse>>('partner/referral/creators')
    .then(res => fromResponseToDataReferralCreatorsAdapter(res))
}

export const detachReferral = (id: IdType) => {
  return Api
    .post<IStatus>('partner/referral/detach', { referral: id })
    .then(res => res)
}
