import { defineStore } from 'pinia'

import { getReferral, getReferralCreators, getReferralHistory, getReferralHistoryDetail } from 'lib/api/modules/partner/creators/referral'
import { IReferral, IReferralCreators, IReferralHistory, IReferralHistoryDetailItem } from 'lib/types/partner/creators/referral'

interface State {
  isFetchingData: boolean
  referral: null | IReferral
  creators: {
    isFetched: boolean
    amount: number
    list: IReferralCreators['list']
  }
  history: IReferralHistory & {
    isFetched: boolean
    page: number
    loading: false
  }
  historyDetail: IReferralHistoryDetailItem[]
}

export const useReferralStore = defineStore({
  id: 'partner-referral',

  state: (): State => ({
    isFetchingData: false,
    referral: null,
    creators: {
      isFetched: false,
      amount: 0,
      list: [],
    },
    history: {
      isFetched: false,
      list: [],
      amount: 0,
      total: 0,
      perPage: 25,
      page: 1,
      loading: false,
    },
    historyDetail: [],
  }),

  actions: {
    async fetchReferral () {
      if (this.referral) return
      this.isFetchingData = true
      const res = await getReferral()
      this.referral = res.data
      this.isFetchingData = false
    },

    async fetchReferralCreators () {
      if (this.creators.isFetched) return

      this.isFetchingData = true
      const res = await getReferralCreators()
      this.creators = {
        ...res,
        isFetched: true,
      }
      this.isFetchingData = false
    },

    async fetchReferralHistory (page?: number) {
      if (this.history.isFetched && !page) return

      if (page) {
        this.history.page = page
      }

      this.isFetchingData = true
      const res = await getReferralHistory(this.history.page)
      this.history = {
        ...this.history,
        ...res,
        isFetched: true,
      }
      this.isFetchingData = false
    },

    async fetchReferralHistoryDetail (date: string) {
      this.isFetchingData = true
      const res = await getReferralHistoryDetail(date)
      this.historyDetail = res.data
      this.isFetchingData = false
    },
  },
})
