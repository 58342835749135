import { Api } from 'lib/api'
import { IResponseData } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { IAgencyResponse } from './types'

export const getAgency = () => {
  return Api
    .get<IResponseData<IAgencyResponse>>('partner/agency')
    .then(res => fromResponseToData(res.data))
}
