import { Api } from 'lib/api/instance'
import { getCampaignTypeBySlug } from 'lib/helpers/getCampaignTypeBySlug'

import { vm } from '@/main'

const searchInCollection = (collection, route) => {
  const searchStr = route.query?.search?.toLowerCase().trim()

  if (collection && searchStr) {
    return collection.filter(item => item.title.toLowerCase().includes(searchStr) || item.id.toString().includes(searchStr))
  }

  return collection
}

export default {
  namespaced: true,
  state: {
    // wallet: undefined,
    // transactions: {
    //   bootstrapped: false,
    //   perPage: 10,
    //   page: 1,
    //   total: 0,
    //   loading: false,
    //   items: [],
    // },
    // referral: null,
    // payouts: {
    //   list: [],
    //   amount: 0,
    //   total: 0,
    //   perPage: 25,
    //   page: 1,
    //   loading: false,
    // },
    // creators: {
    //   amount: 0,
    //   list: [],
    // },
    campaignStructure: null,
  },
  mutations: {
    // setWallet (state, payload) {
    //   state.wallet = payload
    // },
    // setTransactions (state, payload) {
    //   state.transactions.items = payload.data
    //   state.transactions.total = payload.total
    //   state.transactions.perPage = payload.per_page
    //   state.transactions.bootstrapped = true
    // },
    // setTransactionsPage (state, payload) {
    //   state.transactions.page = payload
    // },
    // setPayoutHistoryPage (state, payload) {
    //   state.payouts.page = payload
    // },
    setCampaignStructure (state, payload) {
      state.campaignStructure = payload
    },
    // setReferral (state, payload) {
    //   state.referral = payload
    // },
    // setPayouts (state, payload) {
    //   state.payouts = {
    //     ...state.payouts,
    //     amount: payload.amount,
    //     total: payload.total,
    //     list: payload.data,
    //     perPage: payload.per_page,
    //   }
    // },
    setCreators (state, payload) {
      state.creators = {
        ...state.creators,
        amount: payload.amount,
        list: payload.data,
      }
    },
  },
  actions: {
    // async fetchWallet ({ commit }) {
    //   const res = await Api.get('partner/wallet')
    //   commit('setWallet', res.data)
    // },
    // async fetchTransactions ({ commit, state }) {
    //   const data = { page: state.transactions.page }

    //   try {
    //     state.transactions.loading = true
    //     const res = await Api.get('partner/wallet/transactions', data)
    //     commit('setTransactions', res)
    //   }
    //   finally {
    //     state.transactions.loading = false
    //   }
    // },
    // async fetchReferral ({ commit }) {
    //   const res = await Api.get('partner/referral')
    //   commit('setReferral', res.data)
    // },
    async fetchReferralHistory ({ state, commit }, page) {
      try {
        state.payouts.loading = true
        const res = await Api.get('partner/referral/history', { page })
        commit('setPayouts', res)
      }
      finally {
        state.payouts.loading = false
      }
    },
    // async fetchReferralCreators ({ commit }) {
    //   const res = await Api.get('partner/referral/creators')
    //   commit('setCreators', res)
    // },
    // async fetchDetailPayoutHistory (store, date) {
    //   return await Api.get('partner/referral/history/detail', { date })
    // },
    // setPayoutHistoryPage ({ commit, dispatch }, page) {
    //   commit('setPayoutHistoryPage', page)
    //   dispatch('fetchReferralHistory')
    // },

    // setTransactionsPage ({ commit, dispatch }, page) {
    //   commit('setTransactionsPage', page)
    //   dispatch('fetchTransactions')
    // },

    async fetchCampaignStructure ({ commit }, slug) {
      const type = getCampaignTypeBySlug(slug)
      const res = await Api.get(`partner/campaigns/${type}/structure`, { slug })

      commit('setCampaignStructure', res.data)
    },
  },
  getters: {
    // wallet: ({ wallet }) => wallet,
    // transactions: ({ transactions }) => transactions,
    campaignStructure: ({ campaignStructure }) => campaignStructure,
    // referral: ({ referral }) => referral,
    // payouts: ({ payouts }) => payouts,
    // creators: ({ creators }) => creators,
  },
}
